<template>
  <div>
    <div class="page_container">
      <BackComponent :title="$t('VerifyYourAccount')" />
    </div>

    <div class="card_one page_container">
      <div class="header_desc">
        <h5>{{ $t("chooseDoc") }}</h5>
        <p>
          {{ $t("chooseDocDesc") }}
        </p>
      </div>
    </div>

    <div class="container">
      <div
        class="item"
        v-for="item in items"
        :key="item.text"
        @click="goToUploadPage(item.value)"
      >
        <div class="left">
          <div class="icon" v-html="item.icon"></div>
          <span class="text">{{ item.text }}</span>
        </div>
        <div class="right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M7.42499 16.6004L12.8583 11.1671C13.5 10.5254 13.5 9.47539 12.8583 8.83372L7.42499 3.40039"
              stroke="var(--my-account-card-right-arrow)"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="page_container">
      <div style="margin-top: 24px">
        <ChopbetButton variant="outline" @click="initializeCrips">{{ $t("contactSupport") }} 24/7 </ChopbetButton>
      </div>
    </div>
  </div>
</template>

<script>
import BackComponent from "../../../components/ui/BackComponent.vue";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";

export default {
  name: "Setting",
  components: {
    BackComponent,
    ChopbetButton,
  },
  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      phonenumber: this.getProfile()?.msisdn
        ? String(this.getProfile()?.msisdn).slice(3)
        : "",
      email: this.getProfile()?.email,
      firstName: this.getProfile()?.first_name,
      lastName: this.getProfile()?.last_name,
      dateOfBirth: this.getProfile()?.dob,
      myProfile: this.getProfile(),
      items: [
        {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M14 13H19V11H14V13ZM14 10H19V8H14V10ZM5 16H13V15.45C13 14.7 12.6333 14.1043 11.9 13.663C11.1667 13.2217 10.2 13.0007 9 13C7.8 12.9993 6.83333 13.2203 6.1 13.663C5.36667 14.1057 5 14.7013 5 15.45V16ZM9 12C9.55 12 10.021 11.8043 10.413 11.413C10.805 11.0217 11.0007 10.5507 11 10C10.9993 9.44933 10.8037 8.97867 10.413 8.588C10.0223 8.19733 9.55133 8.00133 9 8C8.44867 7.99867 7.978 8.19467 7.588 8.588C7.198 8.98133 7.002 9.452 7 10C6.998 10.548 7.194 11.019 7.588 11.413C7.982 11.807 8.45267 12.0027 9 12ZM4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.0217 19.805 20.5507 20.0007 20 20H4Z" fill="var(--my-account-card-svg)"/>
</svg>`,
          text: `${this.$t("nationalID1")}`,
        },
        {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M17.25 0.75H6.75C5.95462 0.750869 5.19206 1.06722 4.62964 1.62964C4.06722 2.19206 3.75087 2.95462 3.75 3.75V20.25C3.75087 21.0454 4.06722 21.8079 4.62964 22.3704C5.19206 22.9328 5.95462 23.2491 6.75 23.25H17.25C18.0454 23.2491 18.8079 22.9328 19.3704 22.3704C19.9328 21.8079 20.2491 21.0454 20.25 20.25V3.75C20.2491 2.95462 19.9328 2.19206 19.3704 1.62964C18.8079 1.06722 18.0454 0.750869 17.25 0.75ZM15.6319 13.3364C15.9867 13.7194 16.1602 14.2345 16.1189 14.7867C16.0378 15.8864 15.1992 16.7812 14.25 16.7812C13.3008 16.7812 12.4608 15.8864 12.3806 14.7867C12.3403 14.2289 12.5123 13.7128 12.8653 13.3336C13.2183 12.9544 13.7063 12.75 14.25 12.75C14.5085 12.7463 14.7648 12.7965 15.0028 12.8975C15.2407 12.9985 15.455 13.1479 15.6319 13.3364ZM9 3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75C15 3.94891 14.921 4.13968 14.7803 4.28033C14.6397 4.42098 14.4489 4.5 14.25 4.5H9.75C9.55109 4.5 9.36032 4.42098 9.21967 4.28033C9.07902 4.13968 9 3.94891 9 3.75ZM17.8594 20.8045C17.8054 20.867 17.7383 20.917 17.6629 20.9507C17.5875 20.9845 17.5056 21.0013 17.423 21H11.077C10.9944 21.0013 10.9125 20.9845 10.8371 20.9507C10.7617 20.917 10.6946 20.867 10.6406 20.8045C10.5821 20.7344 10.54 20.6519 10.5176 20.5633C10.4952 20.4747 10.4931 20.3822 10.5113 20.2927C10.6636 19.4695 11.1384 18.7781 11.8847 18.293C12.5475 17.8622 13.3875 17.625 14.25 17.625C15.1125 17.625 15.9525 17.8622 16.6153 18.293C17.3616 18.7781 17.8364 19.4695 17.9888 20.2927C18.007 20.3822 18.0048 20.4747 17.9824 20.5633C17.96 20.6519 17.9179 20.7344 17.8594 20.8045Z" fill="var(--my-account-card-svg)"/>
</svg>`,
          text: `${this.$t("driversLicense")}`,
        },
        {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M6 2C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V4C20 3.46957 19.7893 2.96086 19.4142 2.58579C19.0391 2.21071 18.5304 2 18 2H6ZM12 5C13.3261 5 14.5979 5.52678 15.5355 6.46447C16.4732 7.40215 17 8.67392 17 10C17 11.3261 16.4732 12.5979 15.5355 13.5355C14.5979 14.4732 13.3261 15 12 15C10.6739 15 9.40215 14.4732 8.46447 13.5355C7.52678 12.5979 7 11.3261 7 10C7 8.67392 7.52678 7.40215 8.46447 6.46447C9.40215 5.52678 10.6739 5 12 5ZM12 6C11.59 6.62 11.25 7.29 11.04 8H12.96C12.7452 7.28774 12.4214 6.61307 12 6ZM10.7 6.22C9.78 6.53 9 7.17 8.54 8H10C10.18 7.38 10.4 6.78 10.7 6.22ZM13.29 6.22C13.59 6.78 13.82 7.38 14 8H15.46C15 7.17 14.21 6.54 13.29 6.22ZM8.13 9C8.05 9.32 8 9.65 8 10C8 10.35 8.05 10.68 8.13 11H9.82C9.78 10.67 9.75 10.34 9.75 10C9.75 9.66 9.78 9.33 9.82 9H8.13ZM10.83 9C10.78 9.32 10.75 9.66 10.75 10C10.75 10.34 10.78 10.67 10.83 11H13.17C13.21 10.67 13.25 10.34 13.25 10C13.25 9.66 13.21 9.32 13.17 9H10.83ZM14.18 9C14.22 9.33 14.25 9.66 14.25 10C14.25 10.34 14.22 10.67 14.18 11H15.87C15.95 10.68 16 10.35 16 10C16 9.65 15.95 9.32 15.87 9H14.18ZM8.54 12C9 12.83 9.78 13.46 10.7 13.78C10.4 13.22 10.18 12.63 10 12H8.54ZM11.04 12C11.25 12.72 11.59 13.38 12 14C12.42 13.38 12.75 12.72 12.96 12H11.04ZM14 12C13.82 12.63 13.59 13.22 13.29 13.78C14.21 13.46 15 12.83 15.46 12H14ZM7 17H17V19H7V17Z" fill="var(--my-account-card-svg)"/>
</svg>`,
          text: `${this.$t("nationalPassport")}`,
        },
      ],
    };
  },
  methods: {
    goToUploadPage(type) {
      this.$router.push({ name: "upload", params: { type } });
    },
    initializeCrips() {
      window.$crisp.push(["do", "chat:show"]);
      window.$crisp.push(["do", "chat:open"]);
    },
    handleChatClose() {
      window.$crisp.push(["do", "chat:hide"]);
    },
  },
  mounted() {
    window.$crisp.push(["on", "chat:closed", this.handleChatClose]);

}
};
</script>

<style src="./index.css" scoped></style>
